<template>
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
		<path
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
			d="m2.999 3 3.589 3.59m0 0 3.29 3.289m-3.29-3.29 7.532 7.532M6.588 6.59A9.953 9.953 0 0 1 11.999 5c4.478 0 8.268 2.943 9.542 7a10.025 10.025 0 0 1-4.131 5.411M20.999 21l-3.589-3.589m0 0-3.29-3.29m-.246 4.704c-.607.115-1.234.175-1.875.175-4.477 0-8.268-2.943-9.542-7A9.97 9.97 0 0 1 4.02 8.971m5.857.908a3 3 0 0 1 4.243 4.243M9.877 9.878l4.243 4.242"
		/>
	</svg>
</template>
