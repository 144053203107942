<template>
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
		<path
			fill="currentColor"
			fill-rule="evenodd"
			d="M10 .727c4.616 0 7.433 3.173 9.79 6.6a1.187 1.187 0 0 1 0 1.345c-2.357 3.428-5.174 6.6-9.79 6.6-4.616 0-7.433-3.172-9.79-6.6a1.187 1.187 0 0 1 0-1.344C2.567 3.9 5.384.728 10 .728ZM2.763 8c2.514 3.477 4.584 4.848 7.237 4.848 2.653 0 4.723-1.371 7.236-4.848C14.723 4.523 12.653 3.152 10 3.152 7.347 3.152 5.277 4.523 2.763 8Zm5.722-2.268a2.727 2.727 0 1 1 3.03 4.536 2.727 2.727 0 0 1-3.03-4.536Z"
			clip-rule="evenodd"
		/>
	</svg>
</template>
